<template id="coach_edit">
  <coach-data />
</template>

<script>
import CoachData from "@/components/CoachData.vue";
export default {
  components: {
    CoachData,
  },
};
</script>
