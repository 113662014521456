<template id="coach_data">
  <b-container fluid>
    <b-card
      :title="
        this.$route.params.id
          ? 'Edit ' + (coach ? coach.initial + ' ' + coach.name : 'coach')
          : 'New coach'
      "
      style="background: transparent; border: none"
    >
      <b-card-text>
        <b-row>
          <b-col sm="12" md="8">
            <b-form @submit="onSubmit">
              <b-row align-v="center">
                <b-col cols="2">
                  <picture-input
                    ref="pictureCoach"
                    @change="onAvatarChange"
                    width="150"
                    height="150"
                    size="10"
                    class="my-1"
                    :customStrings="customStrings"
                    :prefill="avatar"
                  ></picture-input>
                </b-col>
                <!--b-col v-if="!form.opta_team_id && form.current_team">
                  <span style="margin-right:10px;">
                    No Opta Mapping
                  </span>
                  <b-button variant="primary" @click="searchOptaTeam"
                    ><b-icon icon="search"></b-icon
                  ></b-button>
                </b-col>
                <b-col v-if="auth.isAdmin() && form.opta_team_id">
                  <b-img
                    :src="logoopta"
                    height="40"
                    title="Opta stats avalable"
                  />
                </b-col-->
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="nomeGroup"
                    horizontal
                    :label="$t('name')"
                    :label-cols="3"
                    label-for="nome"
                    :invalid-feedback="errors.nome ? $t(errors.nome[0]) : ''"
                    :state="errors.nome ? false : null"
                  >
                    <b-form-input
                      id="nome"
                      type="text"
                      v-model="form.nome"
                      :state="errors.nome ? false : null"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="cognomeGroup"
                    horizontal
                    :label="$t('surname')"
                    :label-cols="3"
                    label-for="cognome"
                    :invalid-feedback="
                      errors.cognome ? $t(errors.cognome[0]) : ''
                    "
                    :state="errors.cognome ? false : null"
                  >
                    <b-input-group>
                      <b-form-input
                        id="cognome"
                        type="text"
                        v-model="form.cognome"
                        :state="errors.cognome ? false : null"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          v-if="$route.params.id && coach && coach.allenatore"
                          variant="primary"
                          @click="loadCoach"
                          title="Reload info from Wyscout"
                          ><b-icon icon="arrow-repeat"></b-icon
                        ></b-button>
                        <b-button
                          v-else
                          variant="primary"
                          @click="searchCoach"
                          title="Search coach by Wyscout id"
                          ><b-icon icon="search"></b-icon
                        ></b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    id="dataDiNascitaGroup"
                    horizontal
                    :label="$t('dateOfBirth')"
                    :label-cols="3"
                    label-for="data_nascita"
                  >
                    <b-form-input
                      id="data_nascita"
                      type="date"
                      v-model="form.data_nascita"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="paeseNascitaGroup"
                    horizontal
                    :label="$t('countryOfBirth')"
                    :label-cols="3"
                    label-for="paese_nascita"
                  >
                    <typeahead-country
                      :suggestions="paesi"
                      id="paese_nascita"
                      ref="paese_nascita"
                      @select="onPaeseNascitaSelect"
                      @change="onPaeseNascitaChange"
                      v-model="form.paese_nascita"
                    ></typeahead-country>
                  </b-form-group>
                  <b-form-group
                    id="passaportoGroup"
                    horizontal
                    :label="$t('passports')"
                    :label-cols="3"
                    label-for="passaporto"
                  >
                    <typeahead-country
                      :suggestions="paesi"
                      id="passaporto"
                      ref="passaporto"
                      @select="onPassaportoSelect"
                      @change="onPassaportoChange"
                      v-model="form.passaporto"
                    ></typeahead-country>
                  </b-form-group>
                  <!--p> Coach team: {{ coach && coach.last_stat ? coach.last_stat.team.name : '' }}</p-->
                  <b-form-group
                    v-if="coach && coach.last_stat"
                    id="teamGroup"
                    horizontal
                    label="Opta team"
                    :label-cols="3"
                    label-for="team"
                  >
                    <b-form-input
                      id="team"
                      type="text"
                      v-model="coach.last_stat.team.name"
                      style="font-size: 1rem"
                      plaintext
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="squadraGroup"
                    horizontal
                    label="Team"
                    :label-cols="3"
                    label-for="squadra"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <b-img :src="logo_squadra" width="24" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="squadra"
                        type="text"
                        v-model="form.squadra"
                        style="font-size: 1rem"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-row align-v="center">
                          <b-col cols="3">
                            <b-button
                              variant="primary"
                              @click="loadSquadra"
                              title="Load team from archive"
                              ><b-icon icon="folder-fill"></b-icon
                            ></b-button>
                          </b-col>
                          <b-col>
                            <b-form-checkbox
                              v-model="form.map_opta_team"
                              :value="1"
                              :unchecked-value="0"
                            >
                              map to opta team
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-button type="submit" variant="primary" class="mr-2"
                    >Save</b-button
                  >
                  <b-button type="reset" variant="secondary" class="mr-2"
                    >Cancel</b-button
                  >
                  <!--b-button
                    @click="eliminaAllenatore"
                    variant="danger"
                    v-if="$route.params.id && auth.isAdmin()"
                    >Delete</b-button
                  -->
                </b-col>
              </b-row>
            </b-form>
          </b-col>

          <b-col sm="12" md="4">
            <p>Background image</p>
            <bg-input
              ref="sfondoCoach"
              @change="onSfondoChange"
              size="10"
              class="my-1"
              :customStrings="customStrings"
              :prefill="sfondo"
            ></bg-input>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-modal
      id="loadTeamModal"
      ref="loadTeamModal"
      title="Load team"
      ok-only
      ok-title="Close"
    >
      <div v-if="show_aree">
        <span>Select area: </span>
        <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
          <div v-if="is_search">
            <h4>
              Waiting...
              <img :src="loading" style="width: 150px" />
            </h4>
          </div>
          <div v-if="!is_search && aree.length == 0">
            <h5>No area found!</h5>
          </div>
          <b-row>
            <b-col
              cols="3"
              v-for="area in aree"
              style="padding: 0.2rem"
              :key="area.id"
            >
              <b-card
                class="small text-center"
                style="cursor: pointer; height: 100%"
                @click="scegliArea(area.id)"
              >
                <flag :country="area.sigla" :size="24"></flag><br />
                {{ area.paese }}
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="show_squadre">
        <div v-if="is_search">
          <h4>
            Waiting...
            <img :src="loading" style="width: 150px" />
          </h4>
        </div>
        <div v-if="!is_search && squadre.length == 0">
          <h5>No team found!</h5>
        </div>
        <div v-if="squadre.length > 0">
          <span>Select a team: </span>
          <div style="height: 250px; overflow-y: scroll; overflow-x: hidden">
            <b-row>
              <b-col
                cols="4"
                v-for="team in squadre"
                style="padding: 0.2rem"
                :key="team.id"
              >
                <b-card
                  class="small text-center py-1"
                  style="cursor: pointer; height: 100%"
                  @click="scegliSquadra(team)"
                >
                  <img :src="team.logo_url" width="50" /><br />
                  <strong>{{ team.name }}</strong
                  ><br />
                  <span class="font-size-xs color-rosso">{{
                    team.categoria ? team.categoria.name : ""
                  }}</span
                  ><br />
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
    <!--b-modal
      id="searchOptaTeamModal"
      ref="searchOptaTeamModal"
      title="Search team"
      ok-only
      ok-title="Close"
    >
      <div v-if="is_search">
        <h4>
          Waiting...
          <img :src="loading" style="width: 150px;" />
        </h4>
      </div>
      <p v-if="!is_search && !opta_teams.length">No team found!</p>
      <div v-if="!is_search && opta_teams.length">
        <p>{{ opta_teams.length }} teams found!</p>
        <hr />
        <div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
          <div class="row">
            <div
              v-for="team in opta_teams"
              :key="team.id"
              class="col-6"
              style="border-bottom: 1px solid #343a40; cursor: pointer;"
              v-on:click="selezionaOptaTeam(team.id)"
            >
              <div class="row">
                <div class="col-md-6 my-1" style="font-size: .85rem">
                  <h6 style="display: inline;">
                    {{ team.name }}
                  </h6>
                  <br />
                  <span class="text-muted"
                    >{{ team.league ? team.league.name : "" }} </span
                  ><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal-->
  </b-container>
</template>

<script>
import moment from "moment";
import Auth from "@/lib/auth.js";
import PictureInput from "@/components/PictureInput.vue";
import BgInput from "@/components/BgInput.vue";
import TypeaheadCountry from "@/components/TypeaheadCountry.vue";
import flag from "@/components/Flag.vue";
import logoteam from "@/assets/images/team.png";
import loading from "@/assets/images/loading.gif";
//import logoopta from "@/assets/images/opta.png";

export default {
  data: function () {
    return {
      coach: null,
      image_coach: "",
      image_logo: "",
      image_sfondo: "",
      is_search: false,
      file_avatar: "",
      file_sfondo: "",
      opta_teams: [],
      form: {
        nome: "",
        cognome: "",
        data_nascita: null,
        paese_nascita: 0,
        passaporto: 0,
        squadra: "",
        wyscout_id: "",
        wyscout_team_id: null,
        current_team: null,
        squadra_id: 0,
        // opta_team_id: null,
        allenatore_id: 0,
        map_opta_team: 0,
      },
      paesi: [],
      errors: [],
      customStrings: {
        drag: this.$t("drag"),
        fileSize: this.$t("fileSize"),
        fileType: this.$t("fileType"),
      },
      aree: [],
      competizioni: [],
      squadre: [],
      show_aree: false,
      show_competizioni: false,
      show_squadre: false,
      logoteam,
      loading,
      //  logoopta
    };
  },
  components: {
    PictureInput,
    BgInput,
    TypeaheadCountry,
    flag,
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      var formData = new FormData();
      formData.append("form", JSON.stringify(this.form));
      formData.append("file_avatar", this.file_avatar);
      formData.append("file_sfondo", this.file_sfondo);
      var path = this.$route.params.id
        ? "/scouting/edit/allenatore/" + this.$route.params.id
        : "/scouting/add/allenatore";
      this.$http
        .post(path, formData)
        .then((response) => {
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
          if (response.data.coach_id > 0) {
            this.$router.push({
              name: "ScoutingViewCoach",
              params: { id: response.data.coach_id },
            });
          } else {
            this.$router.push({ name: "ScoutingCoaches" });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.errors = error.response.data;
              for (let index in this.errors) {
                this.$store.commit("msgPush", {
                  msg: this.errors[index],
                  status: 0,
                });
              }
              if (error.response.data.coach_id > 0) {
                this.$router.push({
                  name: "ScoutingViewCoach",
                  params: { id: error.response.data.coach_id },
                });
              }
            }
          }
        });
    },
    onAvatarChange() {
      //	console.log('New picture selected!')
      if (this.$refs.pictureCoach.file) {
        this.file_avatar = this.$refs.pictureCoach.file;
      } else {
        console.log("FileReader Player API not supported: use the <form>");
      }
    },
    onSfondoChange() {
      //	console.log('New picture selected!')
      if (this.$refs.sfondoCoach.file) {
        this.file_sfondo = this.$refs.sfondoCoach.file;
      } else {
        //		console.log('FileReader Player API not supported: use the <form>')
      }
    },
    onPaeseNascitaSelect(item) {
      this.form.paese_nascita = item;
    },
    onPaeseNascitaChange() {
      this.form.paese_nascita = 0;
    },

    onPassaportoSelect(item) {
      this.form.passaporto = item;
    },
    onPassaportoChange() {
      this.form.passaporto = 0;
    },
    loadSquadra: function () {
      this.aree = [];
      this.show_aree = true;
      this.show_squadre = false;
      this.is_search = true;
      if (this.aree.length == 0) {
        this.$http.get("/paesi/squadre").then((response) => {
          this.aree = response.data;
          this.is_search = false;
        });
      }
      this.$refs.loadTeamModal.show();
    },
    scegliArea: function (id) {
      this.squadre = [];
      this.show_aree = false;
      this.is_search = true;
      this.show_squadre = true;
      this.$http.get("/squadre/paese/" + id).then((response) => {
        this.squadre = response.data;
        this.is_search = false;
      });
    },
    scegliSquadra: function (squadra) {
      this.form.squadra_id = squadra.id;
      this.form.squadra = squadra.officialName;
      this.form.wyscout_team_id = squadra.wyscout_id;
      this.form.current_team = squadra;
      if (this.coach.last_stat) {
        if (
          this.coach.last_stat.team.squadra &&
          this.coach.last_stat.team.squadra.id == this.form.squadra_id
        ) {
          this.form.map_opta_team = 1;
        }
      }
      this.$refs.loadTeamModal.hide();
    },

    loadCoach: function () {
      this.$http
        .get("/ws/get/coach/" + this.form.wyscout_id)
        .then((response) => {
          var coach = response.data;
          this.form.nome = coach.firstName;
          this.form.cognome = coach.lastName;
          this.form.data_nascita = coach.birthDate;

          if (coach.currentTeam) {
            this.form.squadra = coach.currentTeam.officialName;
            this.form.wyscout_team_id = coach.currentTeam.wyId;
            this.form.current_team = coach.currentTeam;
          } else {
            this.form.squadra = "";
            this.form.wyscout_team_id = null;
            this.form.current_team = null;
          }
          this.form.squadra_id = 0;
          if (coach.birthArea && coach.birthArea.alpha2code) {
            for (let index in this.paesi) {
              const paese = this.paesi[index];
              if (coach.birthArea.alpha2code == paese.sigla) {
                this.$refs.paese_nascita.setPaese(paese.id, paese.paese);
                break;
              }
            }
          }
          if (coach.passportArea && coach.passportArea.alpha2code) {
            for (let index in this.paesi) {
              const paese = this.paesi[index];
              if (coach.passportArea.alpha2code == paese.sigla) {
                this.$refs.passaporto.setPaese(paese.id, paese.paese);
                break;
              }
            }
          }
        });
    },
    searchCoach: function () {
      if (this.form.cognome && !isNaN(this.form.cognome)) {
        this.$http
          .get("/ws/get/coach/" + this.form.cognome)
          .then((response) => {
            var coach = response.data;
            if (coach && coach.wyId) {
              this.form.nome = coach.firstName;
              this.form.cognome = coach.lastName;
              this.form.data_nascita = coach.birthDate;
              this.form.wyscout_id = coach.wyId;

              if (coach.currentTeam) {
                this.form.squadra = coach.currentTeam.officialName;
                this.form.wyscout_team_id = coach.currentTeam.wyId;
                this.form.current_team = coach.currentTeam;
              } else {
                this.form.squadra = "";
                this.form.wyscout_team_id = null;
                this.form.current_team = null;
              }
              this.form.squadra_id = 0;
              if (coach.birthArea && coach.birthArea.alpha2code) {
                for (let index in this.paesi) {
                  const paese = this.paesi[index];
                  if (coach.birthArea.alpha2code == paese.sigla) {
                    this.$refs.paese_nascita.setPaese(paese.id, paese.paese);
                    break;
                  }
                }
              }
              if (coach.passportArea && coach.passportArea.alpha2code) {
                for (let index in this.paesi) {
                  const paese = this.paesi[index];
                  if (coach.passportArea.alpha2code == paese.sigla) {
                    this.$refs.passaporto.setPaese(paese.id, paese.paese);
                    break;
                  }
                }
              }
            }
          });
      }
    },
    /*searchOptaTeam: function() {
      var query = this.form.current_team.name.trim();
      if (query != "") {
        this.opta_teams = [];
        this.is_search = true;
        this.$refs.searchOptaTeamModal.show();
        this.$http.get("/opta/search/team/" + query).then(response => {
          this.opta_teams = response.data;
          this.is_search = false;
        });
      }
    },
    selezionaOptaTeam: function(team_id) {
      this.$refs.searchOptaTeamModal.hide();
      this.form.opta_team_id = team_id;
    },
    eliminaAllenatore: function() {
      if (confirm("Confirm the elimination of the coach?")) {
        this.$http
          .get("/scouting/delete/allenatore/" + this.$route.params.id)
          .then(response => {
            this.$store.commit("msgPush", {
              msg: response.data.message,
              status: 1
            });
            this.$router.push({ name: "ScoutingCoaches" });
          });
      }
    }   */
  },

  computed: {
    auth: function () {
      return Auth;
    },
    avatar: function () {
      if (this.image_coach != "") {
        return this.image_coach;
      }
      return "";
    },

    sfondo: function () {
      if (this.image_sfondo != "") {
        return this.image_sfondo;
      }
      return "";
    },

    logo_squadra: function () {
      if (this.form.current_team) {
        if (this.form.current_team.logo_url) {
          return this.form.current_team.logo_url;
        }
        if (this.form.current_team.imageDataURL) {
          return this.form.current_team.imageDataURL;
        }
      }
      return this.logoteam;
    },
  },

  created: function () {
    this.$http.get("/paesi").then((response) => {
      this.paesi = response.data;
    });

    if (this.$route.params.id) {
      this.$http
        .get("/scouting/view/allenatore/" + this.$route.params.id + "/1")
        .then((response) => {
          this.coach = response.data;
          if (!this.coach.id) {
            this.$store.commit("msgPush", {
              msg: "Coach not found!",
              status: 0,
            });
            this.$router.push({ name: "ScoutingCoaches" });
          }
          if (this.coach.allenatore) {
            this.form.allenatore_id = this.coach.allenatore.id;
            if (
              this.coach.allenatore.avatar != null &&
              this.coach.allenatore.avatar != ""
            ) {
              this.image_coach = this.coach.allenatore.avatar_url;
            }
            if (
              this.coach.allenatore.sfondo != null &&
              this.coach.allenatore.sfondo != ""
            ) {
              this.image_sfondo = this.coach.allenatore.sfondo_url;
            }
            this.form.nome = this.coach.allenatore.nome;
            this.form.cognome = this.coach.allenatore.cognome;
            this.form.data_nascita = this.coach.allenatore.data_nascita;
            this.form.wyscout_id = this.coach.allenatore.wyscout_id;
            if (this.coach.allenatore.paese_nascita) {
              this.$refs.paese_nascita.setPaese(
                this.coach.allenatore.paese_nascita.id,
                this.coach.allenatore.paese_nascita.paese
              );
            }

            if (this.coach.allenatore.passaporto) {
              this.$refs.passaporto.setPaese(
                this.coach.allenatore.passaporto.id,
                this.coach.allenatore.passaporto.paese
              );
            }
            this.form.squadra = this.coach.allenatore.squadra_allenata
              ? this.coach.allenatore.squadra_allenata.officialName
              : "";
            if (this.coach.allenatore.squadra_allenata) {
              this.form.current_team = this.coach.allenatore.squadra_allenata;
              this.form.squadra_id = this.coach.allenatore.squadra_allenata.id;
              this.form.wyscout_team_id = this.coach.allenatore.squadra_allenata.wyscout_id;
              this.image_logo = this.coach.allenatore.squadra_allenata.logo_url;

              if (this.coach.last_stat) {
                if (
                  this.coach.last_stat.team.squadra &&
                  this.coach.last_stat.team.squadra.id == this.form.squadra_id
                ) {
                  this.form.map_opta_team = 1;
                }
              }

              /*    if (allenatore.squadra_allenata.opta) {
              this.form.opta_team_id =
              allenatore.squadra_allenata.opta.opta_team_id;
            } */
            }
          }
        });
    }
  },
  mounted() {},

  watch: {
    form: {
      handler: function () {
        this.errors = [];
      },
      deep: true,
    },
  },

  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
